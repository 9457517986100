import axios from "axios";
const state = {
  services: [],
  loading: false,
};
const getters = {
  getServices(state) {
    return state.services;
  },
  getLoading(state) {
    return state.loading;
  },
};
const mutations = {
  setSerLoading(state, status) {
    state.loading = status;
  },
  setServices(state, services) {
    state.services = services;
  },
};
const actions = {
  async fetchServices({ commit }, payload) {
    try {
      commit("setSerLoading", true);
      let params = {

      };

      const response = await axios.get("/service", {
        params,
      });
      commit("setServices", response.data.data);
    } catch (err) {
      console.log("err", err);
    } finally {
      commit("setSerLoading", false);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
