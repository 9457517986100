import axios from "axios";
const state = {
  homeList: '',
  loading: false,
};
const getters = {
  getHomeList(state) {
    return state.homeList;
  },
  getLoading(state) {
    return state.loading;
  },
};
const mutations = {
  setHoLoading(state, status) {
    state.loading = status;
  },
  setHomeList(state, homeList) {
    state.homeList = homeList;
  },
};
const actions = {
  async fetchHomeList({ commit }, payload) {
    try {
      commit("setHoLoading", true);
      let params = {
        // size: 10,
        // page: payload.page,
        // paginated: payload.paginated,
      };
    //   for (let key in payload.params) {
    //     if (payload.params[key]) {
    //       params[key] = payload.params[key];
    //     }
    //   }
      const response = await axios.get("/title/home", {
        params,
      });
      commit("setHomeList", response.data.data);
    //  if(payload.paginated){
    //   commit("setTableData", {
    //     tableHeaders: [
    //       "id",
    //       "education class name",
    //       "room",
    //       "mainview.class",
    //       "total students",
    //       "settings",
    //     ],
    //     tableData: response.data.classes,
    //     accessTableData: [
    //       ["id"],
    //       ["name"],
    //       ["room", "name"],
    //       ["grade", "name"],
    //       ["registration_record_count"],
    //     ],
    //     tableMeta: {
    //       total: response.data.meta.total,
    //       page: response.data.meta.current_page,
    //     },
    //   });
    //  } else {
    //   commit("setTableData", {
    //     tableHeaders: [
    //       "id",
    //       "education class name",
    //       "room",
    //       "mainview.class",
    //       "total students",
    //       "settings",
    //     ],
    //     tableData: response.data.classes,
    //     accessTableData: [
    //       ["id"],
    //       ["name"],
    //       ["room", "name"],
    //       ["grade", "name"],
    //       ["registration_record_count"],
    //     ],
    //     tableMeta: {
    //       total: null,
    //       page: null,
    //     },
    //   });
    //  }
    } catch (err) {
      console.log("err", err);
    } finally {
      commit("setHoLoading", false);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
