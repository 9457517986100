import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import Notifications from "../helpers/toast-notifications";
import i18n from "../i18n";
import tablesStore from "./tablesStore";
import services from "./modules/users/services";
import about from "./modules/about";
import home from "./modules/home";
import router from "../router";

Vue.use(Vuex);
// online serveer domain
// https://bahlol-demo-back.point-dev.net
// https://bahlol-back.point-dev.net/api
// https://bahlol-back.incentive-dev.com/api
// https://incentive-dev.com
// http://192.168.1.6:8080
// https://back-demo.incentive-dev.com
// http://127.0.0.1:8080
// https://back-ai.incentive-dev.com

export default new Vuex.Store({
  state: {
    serverDomain: "https://back-ai.incentive-dev.com/api",
    barImage: "",
    drawer: null,
    userData: {
      username: "",
      abilities: [],
    },
    routeNameAddition: "",
    isDarkMode: false,
  },
  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_DRAWER(state, payload) {
      console.log("in set drawer?");
      state.drawer = payload;
    },
    setUserData(state, userInfo) {
      state.userData.username = userInfo.user_name;
      state.userData.abilities = userInfo.ability;
    },
    setRouteNameAddition(state, addition) {
      state.routeNameAddition = addition;
    },
    SET_DARK_MODE(state, payload) {
      state.isDarkMode = payload;
    },
  },
  actions: {
    async userLogin({ commit }, userData) {
      try {
        axios.defaults.headers.common["term"] = userData.termId;
        localStorage.setItem("nawader_term", userData.termId);

        const logInResponse = await axios.post("/auth/login", {
          username: userData.username,
          password: userData.password,
        });

        console.log("login response", logInResponse);
        commit("setUserData", logInResponse.data.data);
        localStorage.setItem(
          "nawader_user_data",
          JSON.stringify(logInResponse.data.data)
        );
        localStorage.setItem(
          "nawader_access_token",
          logInResponse.data.data.access_token
        );
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + logInResponse.data.data.access_token;
        Notifications(
          i18n.t("login success"),
          { timeout: 1000, rtl: true },
          "success"
        );
        router.push("/");
      } catch (err) {
      } finally {
      }
    },
    autoLogIn({ commit }) {
      // commit(
      //   "setUserData",
      //   JSON.parse(localStorage.getItem("nawader_user_data"))
      // );
      // axios.defaults.headers.common["term"] =
      //   localStorage.getItem("nawader_term");
      // axios.defaults.headers.common["Authorization"] =
      //   "Bearer " + localStorage.getItem("nawader_access_token");
    },
  },
  getters: {
    getUserName(state) {
      return state.userData.username;
    },
    getUserAbilities(state) {
      return state.userData.abilities;
    },
    getRouteNameAddition(state) {
      return state.routeNameAddition;
    },
  },
  modules: {
    tablesStore,
    services,
    about,
    home,
  },
});
