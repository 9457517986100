import axios from "axios";
const state = {
  about: [],
  loading: false,
};
const getters = {
  getAbout(state) {
    return state.about;
  },
  getLoading(state) {
    return state.loading;
  },
};
const mutations = {
  setSerLoading(state, status) {
    state.loading = status;
  },
  setAbout(state, about) {
    state.about = about;
  },
};
const actions = {
  async fetchAbout({ commit }, payload) {
    try {
      commit("setSerLoading", true);
      let params = {

      };

      const response = await axios.get("/about-us", {
        params,
      });
      commit("setAbout", response.data.data);
   
    } catch (err) {
      console.log("err", err);
    } finally {
      commit("setSerLoading", false);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
