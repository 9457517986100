import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const routes = [
  {
    path: "/Login",
    component: () => import("@/views/dashboard/Login"),
  },
  {
    path: "/",
    component: () => import("@/views/dashboard/Index"),

    children: [
      {
        name: "أهلاً بك في لوحة التحكم ",
        path: "",
        component: () => import("@/views/dashboard/Dashboard"),
      },
      {
        name: "أهلاً بك في لوحة التحكم ",
        path: "/",
        component: () => import("@/views/dashboard/Dashboard"),
      },
    ],
  },
];

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
